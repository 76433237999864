<template>
  <div style="padding: 10px">
    <div style="margin: 10px 0" v-if="this.userId === 1">
      <!--<el-button type="primary" @click="exportUser">导出</el-button>-->
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <!--搜索区域-->
    <el-table
            v-loading="loading"
            :data="tableData"
            border
            stripe
            style="width: 100%">
      <el-table-column
              prop="createTime"
              label="日期">
      </el-table-column>
      <el-table-column
              prop="recruit"
              label="招募">
      </el-table-column>
      <el-table-column
              prop="box"
              label="宝箱">
      </el-table-column>
      <el-table-column
              prop="fishGod"
              label="金鱼竿">
      </el-table-column>
      <el-table-column
              prop="gold"
              label="金砖">
      </el-table-column>
      <el-table-column label="操作" width="200px" v-if="this.userId === 1">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin: 10px 0">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
    </div>
    <!--新增编辑表单-->
    <el-dialog title="新增/编辑" v-model="dialogVisible" width="30%">
      <el-form :model="form">
        <el-form-item label="日期">
          <el-date-picker value-format="YYYY-MM-DD" format="YYYY-MM-DD" v-model="form.createTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="招募">
          <el-input type="number" v-model="form.recruit"></el-input>
        </el-form-item>
        <el-form-item label="木箱子">
          <el-input type="number" v-model="form.woodBox"></el-input>
        </el-form-item>
        <el-form-item label="青铜箱子">
          <el-input type="number" v-model="form.bronzeBox"></el-input>
        </el-form-item>
        <el-form-item label="黄金箱子">
          <el-input type="number" v-model="form.goldBox"></el-input>
        </el-form-item>
        <el-form-item label="铂金箱子">
          <el-input type="number" v-model="form.platinumBox"></el-input>
        </el-form-item>
        <el-form-item label="黄金鱼竿">
          <el-input type="number" v-model="form.fishGod"></el-input>
        </el-form-item>
        <el-form-item label="金砖">
          <el-input type="number" v-model="form.gold"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

  import request from "@/utils/request";

  export default {
    name: 'towerFormation',
    components: {},
    data() {
      return {
        dialogVisible: false,
        form:{
          createTime:'',
        },
        userId: '',
        loading: true,
        search: {},
        query: {},
        currentPage: 1,
        pageSize: 10,
        total: 0,
        tableData: [],
      }
    },
    created() {
      this.load()
      let userStr = sessionStorage.getItem("user") || "{}"
      let user = JSON.parse(userStr)
      this.userId = user.id;
    },
    methods: {
      // 点击新增按钮
      add() {
        this.dialogVisible = true
        this.form = {}
      },
      handleEdit(row) {
        this.form = JSON.parse(JSON.stringify(row))
        this.dialogVisible = true
      },
      save() {
        this.loading = true;
        let userStr = sessionStorage.getItem("user") || "{}"
        let user = JSON.parse(userStr)
        this.form.userId = user.id
        if (this.form.id) {  // 更新
          request.post("/saltedFishSource/update", this.form).then(res => {
            if (res.code === '0') {
              this.$router.push('/saltedFish/goldFishResult')
            } else {
              this.$message({
                type: "error",
                message: res.msg
              })
            }
            this.load() // 刷新表格的数据
            this.loading = false
            this.dialogVisible = false  // 关闭弹窗
          })
        } else {  // 新增
          request.post("/saltedFishSource/save", this.form).then(res => {
            if (res.code === '0') {
              this.$router.push('/saltedFish/goldFishResult')
            } else {
              this.$message({
                type: "error",
                message: res.msg
              })
            }
            this.form = '';
            this.load(); // 刷新表格的数据
            this.loading = false
            this.dialogVisible = false  // 关闭弹窗
          })
        }
      },
      exportUser() {
        location.href = "http://182.92.223.123:9090/saltedFishSource/export";
      },
      resetData() {
        this.search = {}
        this.load()
      },
      load() {
        this.loading = true
        const searchForm = {...this.search}
        let obj = Object.assign(searchForm, this.query)
        let reqData = {
          ...obj
        }
        reqData.current = this.currentPage
        reqData.size = this.pageSize
        request.get("/saltedFishSource/list", {
          params: reqData
        }).then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.total = res.data.total
        })
      },
      handleSizeChange(pageSize) {   // 改变当前每页的个数触发
        this.pageSize = pageSize
        this.load()
      },
      handleCurrentChange(pageNum) {  // 改变当前页码触发
        this.currentPage = pageNum
        this.load()
      }
    }
  }
</script>
